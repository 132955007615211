import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    rewards: {data: [], loading: false},
    sections: {data: [], loading: false}
}

export const selectMenuSlice = createSlice({
    name: 'selectMenu',
    initialState,
    reducers: {
        setRewardsMenuAction: (state, action) => {
            state.rewards.data = action.payload;
        },
        setRewardsMenuLoading: (state, action) => {
            state.rewards.loading = action.payload;
        },
        setSectionsMenuAction: (state, action) => {
            state.sections.data = action.payload;
        },
        setSectionsMenuLoading: (state, action) => {
            state.sections.loading = action.payload;
        }
    }
})

export default selectMenuSlice.reducer;
export const {
    setRewardsMenuAction,
    setRewardsMenuLoading,
    setSectionsMenuLoading,
    setSectionsMenuAction
} = selectMenuSlice.actions;