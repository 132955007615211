import { createSlice } from "@reduxjs/toolkit";
import { MODULE_NAME } from "../constants/routes";

const initialState = {
    all: {
        data: [],
        loading: false,
    },
    show: {
        data: {},
        loading: false
    },
    deleteLoading: false
}

export const settingSlice = createSlice({
    name: MODULE_NAME,
    initialState,
    reducers: {
        setAllSettingAction: (state, action) => {
            state.all.data = action.payload;
        },
        setAllSettingLoadingAction: (state, action) => {
            state.all.loading = action.payload;
        },
    }
})

export default settingSlice.reducer;
export const {
    setAllSettingAction,
    setAllSettingLoadingAction,
} = settingSlice.actions;