import {createSlice} from "@reduxjs/toolkit";
import {paginationInitialValues, setPaginationAction} from "../../../utility/helpers/reduxHelper";
import { MODULE_NAME } from "../constants/routes";

const initialState = {
    all: {
        data: [],
        loading: false,
        ...paginationInitialValues()
    },
    show: {
        data: {},
        loading: false
    },
    deleteLoading: false
}

export const bannerSlice = createSlice({
    name: MODULE_NAME,
    initialState,
    reducers: {
        setAllBannerAction: (state, action) => {
            state.all.data = action.payload;
        },
        setAllBannerMetaAction: (state, action) => {
            state.all.meta = setPaginationAction(action)
        },
        setAllBannerLoadingAction: (state, action) => {
            state.all.loading = action.payload;
        },
        setOneStuffAction: (state, action) => {
            state.show.data = action.payload;
        },
        setOneStuffLoadingAction: (state, action) => {
            state.show.loading = action.payload;
        }
    }
})

export default bannerSlice.reducer;
export const {
    setAllBannerAction,
    setAllBannerMetaAction,
    setAllBannerLoadingAction,
    setOneStuffAction,
    setOneStuffLoadingAction
} = bannerSlice.actions;