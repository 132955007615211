import { combineReducers } from "@reduxjs/toolkit";
import loginReducer from "../modules/auth/redux/loginReducer";
import bannerReducer from "../modules/banner/redux";
import contactUsReducer from '../modules/contact/redux';
import notificationReducer from '../modules/notification/redux/notifications';
import sectionsReducer from '../modules/sections/redux';
import servicesReducer from '../modules/services/redux';
import settingReducer from '../modules/setting/redux';
import stuffReducer from '../modules/stuff/redux';
import testimonialsReducer from '../modules/testimonials/redux';
import workingHoursReducer from '../modules/workingHours/redux';
import loading from '../redux/loading';
import layout from "./layout";
import navbar from "./navbar";
import selectMenuReducer from './selectMenu';

export default combineReducers({
    navbar,
    layout,
    loginReducer,
    notificationReducer,
    selectMenuReducer,
    loading,
    servicesReducer,
    stuffReducer,
    settingReducer,
    sectionsReducer,
    contactUsReducer,
    workingHoursReducer,
    bannerReducer,
    testimonialsReducer
})
