import {createSlice} from "@reduxjs/toolkit";
import {paginationInitialValues, setPaginationAction} from "../../../utility/helpers/reduxHelper";
import { MODULE_NAME } from "../constants/routes";

const initialState = {
    all: {
        data: [],
        loading: false,
        ...paginationInitialValues()
    },
    show: {
        data: {},
        loading: false
    },
    deleteLoading: false
}

export const ServicesSlice = createSlice({
    name: MODULE_NAME,
    initialState,
    reducers: {
        setAllServicesAction: (state, action) => {
            state.all.data = action.payload;
        },
        setAllServicesMetaAction: (state, action) => {
            state.all.meta = setPaginationAction(action)
        },
        setAllServicesLoadingAction: (state, action) => {
            state.all.loading = action.payload;
        },
        setOneServiceAction: (state, action) => {
            state.show.data = action.payload;
        },
        setOneServiceLoadingAction: (state, action) => {
            state.show.loading = action.payload;
        }
    }
})

export default ServicesSlice.reducer;
export const {
    setAllServicesAction,
    setAllServicesMetaAction,
    setAllServicesLoadingAction,
    setOneServiceAction,
    setOneServiceLoadingAction
} = ServicesSlice.actions;