import {createSlice} from "@reduxjs/toolkit";
import {paginationInitialValues, setPaginationAction} from "../../../utility/helpers/reduxHelper";
import { MODULE_NAME } from "../constants/routes";

const initialState = {
    all: {
        data: [],
        loading: false,
        ...paginationInitialValues()
    },
    show: {
        data: {},
        loading: false
    },
    deleteLoading: false
}

export const sectionsSlice = createSlice({
    name: MODULE_NAME,
    initialState,
    reducers: {
        setAllSectionAction: (state, action) => {
            state.all.data = action.payload;
        },
        setAllSectionMetaAction: (state, action) => {
            state.all.meta = setPaginationAction(action)
        },
        setAllSectionLoadingAction: (state, action) => {
            state.all.loading = action.payload;
        },
        setOneSectionAction: (state, action) => {
            state.show.data = action.payload;
        },
        setOneSectionLoadingAction: (state, action) => {
            state.show.loading = action.payload;
        }
    }
})

export default sectionsSlice.reducer;
export const {
    setAllSectionAction,
    setAllSectionMetaAction,
    setAllSectionLoadingAction,
    setOneSectionAction,
    setOneSectionLoadingAction
} = sectionsSlice.actions;