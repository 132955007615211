import {createSlice} from "@reduxjs/toolkit";
import {paginationInitialValues, setPaginationAction} from "../../../utility/helpers/reduxHelper";

const initialState = {
    all: {
        data: [],
        loading: false,
        ...paginationInitialValues()
    },
    show: {
        data: {},
        loading: false
    },
    deleteLoading: false
}

export const testimonialsSlice = createSlice({
    name: "testimonials",
    initialState,
    reducers: {
        setAllContactAction: (state, action) => {
            state.all.data = action.payload;
        },
        setAllContactMetaAction: (state, action) => {
            state.all.meta = setPaginationAction(action)
        },
        setAllContactLoadingAction: (state, action) => {
            state.all.loading = action.payload;
        },
        setOneContactAction: (state, action) => {
            state.show.data = action.payload;
        },
        setOneContactLoadingAction: (state, action) => {
            state.show.loading = action.payload;
        },
        setOneContactAction: (state, action) => {
            state.show.data = action.payload;
        },
        setOneContactLoadingAction: (state, action) => {
            state.show.loading = action.payload;
        }
    }
})

export default testimonialsSlice.reducer;
export const {
    setAllContactAction,
    setAllContactMetaAction,
    setAllContactLoadingAction,
    setOneContactAction,
    setOneContactLoadingAction
} = testimonialsSlice.actions;