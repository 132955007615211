import {createSlice} from "@reduxjs/toolkit";
import {paginationInitialValues, setPaginationAction} from "../../../utility/helpers/reduxHelper";
import { MODULE_NAME } from "../constants/routes";

const initialState = {
    all: {
        data: [],
        loading: false,
        ...paginationInitialValues()
    },
    show: {
        data: {},
        loading: false
    },
    deleteLoading: false
}

export const stuffSlice = createSlice({
    name: MODULE_NAME,
    initialState,
    reducers: {
        setAllStuffAction: (state, action) => {
            state.all.data = action.payload;
        },
        setAllStuffMetaAction: (state, action) => {
            state.all.meta = setPaginationAction(action)
        },
        setAllStuffLoadingAction: (state, action) => {
            state.all.loading = action.payload;
        },
        setOneStuffAction: (state, action) => {
            state.show.data = action.payload;
        },
        setOneStuffLoadingAction: (state, action) => {
            state.show.loading = action.payload;
        }
    }
})

export default stuffSlice.reducer;
export const {
    setAllStuffAction,
    setAllStuffMetaAction,
    setAllStuffLoadingAction,
    setOneStuffAction,
    setOneStuffLoadingAction
} = stuffSlice.actions;